
import React, { useEffect, useState } from 'react'
import useWidth from '../../hooks/window/useWidth'
import { widths } from '../../styles/breakpoints'

type ImageProps = {
  arrayNumbers: number[];
  arrayNumbersHeight?: number[];
  pathSrc: string;
  altDescription: string;
  className?: string;
}

const ImageWebp = ({ arrayNumbers, arrayNumbersHeight, pathSrc, altDescription, className }: ImageProps) => {
  const width = useWidth()
  const [ isWidth, setIsWidth ] = useState(0)
  const [ isHeight, setIsHeight ] = useState(0)

  useEffect(() => {
    if (width < widths.md) {
      setIsWidth(arrayNumbers[0])
      arrayNumbersHeight && setIsHeight(arrayNumbersHeight[0])
    } else if (width >= widths.md && width < widths.lg) {
      setIsWidth(arrayNumbers[1])
      arrayNumbersHeight && setIsHeight(arrayNumbersHeight[1])
    } else if (width >= widths.lg && width < widths.xl) {
      setIsWidth(arrayNumbers[2])
      arrayNumbersHeight && setIsHeight(arrayNumbersHeight[2])
    } else if (width >= widths.xxl && width < widths.xxxl) {
      setIsWidth(arrayNumbers[3])
      arrayNumbersHeight && setIsHeight(arrayNumbersHeight[3])
    } else if (width >= widths.xxxl) {
      setIsWidth(arrayNumbers[4] ? arrayNumbers[4] : arrayNumbers[3])
      arrayNumbersHeight && setIsHeight(arrayNumbersHeight[4])
    }
  }, [ width, widths, isHeight ])

  return (
    <img
      src={pathSrc}
      alt={altDescription}
      className={className}
      width={isWidth}
      height={isHeight || '100%'}
    />
  )
}

export default ImageWebp
